import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ContentWrapper, Countdown } from '../../components';
// import { ContentWrapper, ContestResults, Countdown } from '../../components';
import { useQuizQuery } from '../../queries';
import { useAuth } from '../../store';
import { AppRoutes } from '../../types/routes';
import {
  getContestCountdownData,
  isContestEnded,
  isContestStarted,
  isRegistrationStarted,
  // isTutorialRegistrationStarted,
} from '../../utils';
import { REGISTRATION_START_DATE } from '../../common/constants';
// import { TUTORIAL_REGISTRATION_START_DATE } from '../../common/constants';

export const QuizPage = () => {
  const navigate = useNavigate();
  const { accessToken } = useAuth();
  const countdownData = getContestCountdownData();

  const [, setCountdownTitle] = useState(countdownData.title);
  const [, setCountdownTime] = useState(countdownData.time);

  const { data: quizStatus, isLoading, isError } = useQuizQuery();

  const goToQuestionPage =
    isContestStarted() &&
    accessToken &&
    !isContestEnded() &&
    (quizStatus?.total_number_of_responses ?? 0) < 50 &&
    !isLoading &&
    !isError;

  const [, setShowCountdown] = useState(!goToQuestionPage);

  if (isRegistrationStarted() && !accessToken) {
    navigate(AppRoutes.Register);
  }

  useEffect(() => {
    const timerId = setInterval(() => {
      if (goToQuestionPage) {
        setShowCountdown(false);
        navigate(`${AppRoutes.Quiz}/${(quizStatus?.total_number_of_responses ?? 0) + 1}`);
        clearInterval(timerId);
      } else {
        const { title, time } = getContestCountdownData();
        setShowCountdown(true);
        setCountdownTitle(title);
        setCountdownTime(time);
      }

      if (isRegistrationStarted() && !accessToken) {
        navigate(AppRoutes.Register);
      }
    }, 1000);

    return () => clearInterval(timerId);
  }, [accessToken, goToQuestionPage, navigate, quizStatus?.total_number_of_responses]);

  return (
    <ContentWrapper>
      {!isRegistrationStarted() && (
        // <div className="flex flex-col justify-center gap-3 text-center">
        //   <div className="text-xl text-slate-500">
        //     <a href={AppRoutes.Tutorial} className="primary-button">
        //       Înscrie-te la întâlnirile online de informare
        //     </a>
        //   </div>
        // </div>
        <Countdown
          title="Perioada înscrierilor în concursul Euro Quiz: 7 aprilie – 17 mai 2025."
          endTime={REGISTRATION_START_DATE.getTime()}
        />
      )}

      {goToQuestionPage && (
        <div className="flex flex-col justify-center gap-3 text-center py-6 sm:py-8">
          <div className="text-xl text-bold">Vă rugăm așteptați!</div>
          <div>
            Veți fi redirecționat către pagina cu întrebări. În cazul în care nu se întâmplă acest
            lucru, vă rugăm dați click{' '}
            <Link className="text-primary underline" to={`${AppRoutes.Quiz}/1`}>
              aici
            </Link>
            .
          </div>
        </div>
      )}

      {/* {accessToken && <ContestResults />} */}
    </ContentWrapper>
  );
};
