import { Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Footer, Header } from './components';
import { Home } from './containers/home';
import { Login } from './containers/login';
import { QuizQuestionPage } from './containers/quiz-question';
import { RegistrationPage } from './containers/register';
// import { TutorialPage } from './containers/tutorial';
import { RequireAuth } from './components/RequireAuth';
import { RulesPage } from './containers/rules';
import { InfoPage } from './containers/info';
import { ResetPage } from './containers/reset';
import { AppRoutes } from './types/routes';
import { UserPage } from './containers/user';
import { QuizPage } from './containers/quiz';
import { OnlineMeetingRegister } from './containers/online-meeting-register';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Header />
      <Routes>
        <Route path={AppRoutes.Home} element={<Home />} />
        <Route path={AppRoutes.Login} element={<Login />} />
        <Route path={AppRoutes.Register} element={<RegistrationPage />} />
        <Route path={AppRoutes.Tutorial} element={<OnlineMeetingRegister />} />
        <Route path={AppRoutes.ResetPassword} element={<ResetPage />} />
        <Route
          path="/user"
          element={
            <RequireAuth>
              <UserPage />
            </RequireAuth>
          }
        />
        <Route path={AppRoutes.Quiz} element={<QuizPage />} />
        <Route
          path={`${AppRoutes.Quiz}/:id`}
          element={
            <RequireAuth>
              <QuizQuestionPage />
            </RequireAuth>
          }
        />
        <Route path={AppRoutes.Rules} element={<RulesPage />} />
        <Route path={AppRoutes.Info} element={<InfoPage />} />
        {/* <Route path="aaaabbbccc" element={<RegistrationPage />} /> */}
        <Route path="*" element={<div>todo not found</div>} />
      </Routes>
      <Footer />
    </QueryClientProvider>
  );
}

export default App;
