import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField } from '../TextField';
import { CookieKeys, Grade, RegisterFormData } from '../../types';
import { registrationFormSchema } from '../../validation';
import { DatePicker } from '../DatePicker';
import { Dropdown } from '../Dropdown';
import { Checkbox } from '../Checkbox';
import { useCountiesQuery, useRegisterMutation } from '../../queries';
import { setCookie } from '../../utils/cookies';
import { LoadingSpiner } from '../LoadingSpiner';
import { Alert } from '../Alert';
import { useAuth } from '../../store';

export function RegistrationForm() {
  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setError,
  } = useForm<RegisterFormData>({
    defaultValues: {
      students: new Array(4).fill({}),
    },
    mode: 'all',
    resolver: yupResolver(registrationFormSchema),
  });

  const { fields: students } = useFieldArray({
    name: 'students',
    control,
  });

  const { setAuth } = useAuth();
  const { data: counties } = useCountiesQuery();

  const { mutate: registerUser, isLoading: isCreatingUser } = useRegisterMutation();

  const [message, setMessage] = useState<string>();

  const onSubmit: SubmitHandler<RegisterFormData> = (data) => {
    registerUser(data, {
      onSuccess: (res) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { access_token } = res;
        setCookie(CookieKeys.Token, access_token);
        setAuth(res);
        navigate('/');
      },
      onError: (error) => {
        if (error.errors) {
          Object.keys(error.errors).forEach((key: string) => {
            if (key === 'email') {
              setError(key, {
                type: 'required',
                message: 'Această adresă de email a fost folosită deja',
              });
            }

            if (key === 'phone') {
              setError(key, {
                type: 'required',
                message: 'Aceast număr de telefon a fost folosit deja',
              });
            }
          });
          return;
        }

        setMessage('Am întâmpinat o problemă. Vă rugăm verificați datele și încercați din nou.');
      },
    });
  };

  return (
    <div className="mt-10 sm:mt-0">
      <div className="mt-5 md:mt-0 max-w-[800px] mx-auto ">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="shadow bg-gray-50 overflow-hidden sm:rounded-md">
            <div className="px-4 py-5 sm:p-6">
              <div className="text-2xl mb-4">
                Formular înscriere Concurs Național Euro Quiz 2025
              </div>
              <h3 className="text-lg">Echipa</h3>
              <div className="grid grid-cols-8 gap-x-6">
                <div className="col-span-8">
                  <TextField
                    errors={errors.team_name}
                    label="Nume"
                    name="team_name"
                    placeholder="Nume echipă"
                    register={register}
                    required
                  />
                </div>
              </div>

              <div className="border-b border-slate-300 mb-4 w-full" />

              <h3 className="text-lg">Unitate de învățământ</h3>
              <div className="grid grid-cols-8 gap-x-6">
                <div className="col-span-8 md:col-span-4">
                  <TextField
                    errors={errors.school}
                    label="Nume"
                    name="school"
                    placeholder="Școala gimnazială nr. 1"
                    register={register}
                    required
                  />
                </div>

                <div className="col-span-8 sm:col-span-4 md:col-span-2">
                  <TextField
                    errors={errors.locality}
                    label="Localitate"
                    name="locality"
                    register={register}
                    required
                  />
                </div>

                <div className="col-span-8 sm:col-span-4 md:col-span-2">
                  <Dropdown
                    defaultValue=""
                    errors={errors.county_id}
                    label="Județ"
                    required
                    {...register(`county_id`)}
                  >
                    <option disabled value="">
                      -- județul --
                    </option>
                    {counties?.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Dropdown>
                </div>
              </div>

              <div className="border-b border-slate-300 mb-4 w-full" />

              <h3 className="text-lg">Coordonator</h3>
              <div className="grid grid-cols-8 gap-x-6">
                <div className="col-span-8 md:col-span-4">
                  <TextField
                    errors={errors.name}
                    label="Nume și prenume"
                    name="name"
                    register={register}
                    required
                  />
                </div>

                <div className="col-span-8 sm:col-span-3 md:col-span-2">
                  <TextField
                    errors={errors.phone}
                    label="Număr telefon"
                    name="phone"
                    placeholder="0712345678"
                    register={register}
                    required
                  />
                </div>

                <div className="col-span-8 sm:col-span-5 md:col-span-4">
                  <TextField
                    errors={errors.email}
                    label="Adresă e-mail"
                    name="email"
                    placeholder="coordonator@email.com"
                    register={register}
                    required
                  />
                </div>
                <div className="col-span-8 sm:col-span-5 md:col-start-1 md:col-end-4">
                  <TextField
                    errors={errors.password}
                    label="Parolă"
                    name="password"
                    placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
                    register={register}
                    required
                    type="password"
                  />
                </div>
                <div className="col-span-8 sm:col-span-5 md:col-start-1 md:col-end-4">
                  <TextField
                    errors={errors.password_confirmation}
                    label="Confirmare parolă"
                    name="password_confirmation"
                    placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
                    register={register}
                    required
                    type="password"
                  />
                </div>
              </div>

              <div className="border-b border-slate-300 mb-4 w-full" />

              {students.map((student, index) => (
                <React.Fragment key={student.id}>
                  <h3 className="text-lg">{`Elev ${index + 1}`}</h3>
                  <div className="grid grid-cols-10 gap-x-6">
                    <div className="col-span-10 md:col-span-4">
                      <TextField
                        errors={errors.students?.[index]?.name}
                        label="Nume și prenume"
                        name={`students.${index}.name`}
                        register={register}
                        required
                      />
                    </div>

                    <div className="col-span-10 sm:col-span-5 md:col-span-3">
                      <DatePicker
                        errors={errors.students?.[index]?.dob}
                        label="Data nașterii"
                        name={`students.${index}.dob`}
                        register={register}
                        required
                      />
                    </div>

                    <div className="col-span-10 sm:col-span-5 md:col-span-3">
                      <Dropdown
                        errors={errors.students?.[index]?.class}
                        label="Clasa / an de studiu"
                        required
                        {...register(`students.${index}.class`)}
                      >
                        <option value={Grade.Fifth}>a V-a</option>
                        <option value={Grade.Sixth}>a VI-a</option>
                        <option value={Grade.Seventh}>a VII-a</option>
                        <option value={Grade.Eighth}>a VIII-a</option>
                      </Dropdown>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
            <div className="px-4 py-3 sm:px-6">
              <Checkbox
                errors={errors.rules}
                label="Confirm că am citit și înțeles regulamentul concursului."
                {...register('rules')}
              />
              <Checkbox
                errors={errors.gdpr}
                label={`Confirm că dețin acordurile pentru utilizarea imaginii și GDPR pentru cei 4 membri ai echipei și le pun la dispoziția organizatorului pentru desfășurarea concursului Euro Quiz, ediția ${new Date().getFullYear()}.`}
                {...register('gdpr')}
              />
              <div className="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-end">
                {message && (
                  <div className="sm:mr-auto">
                    <Alert message={message} type="error" />
                  </div>
                )}
                {isCreatingUser && <LoadingSpiner />}
                <button disabled={isCreatingUser} type="submit" className="primary-button">
                  Creează cont
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
